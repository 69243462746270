import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "border-radius-lg" }
const _hoisted_2 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_empty_state = _resolveComponent("tm-empty-state")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_empty_state, {
      icon: (_ctx.type === 'brand') ? 'tmi-star-circle' : 'tmi-campaign',
      title: `No registered ${_ctx.type}s`,
      description: "Register your brand and campaign to become 10DLC compliant<br> and continue sending text messages to North America.",
      size: "medium",
      "background-lift": ""
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_tm_button, {
            color: "primary",
            size: "xLarge",
            to: { name: 'base.services.numbers.10DLC.registration' }
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Register " + _toDisplayString(_ctx.type), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ])
      ]),
      _: 1
    }, 8, ["icon", "title"])
  ]))
}