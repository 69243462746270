
import type { PropType } from 'vue'
import {
  defineComponent
} from 'vue'
import TmEmptyState from '@/components/shared/states/TmEmptyState.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmEmptyState,
    TmButton,
  },
  props: {
    type: {
      type: String as PropType<'brand' | 'campaign'>,
      required: true,
    },
  },
})
